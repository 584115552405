export default [
  {
    path: '/pendidikan',
    name: 'pendidikan',
    component: () => import('@/views/pendidikan/Pendidikan.vue'),
    meta: {
      pageTitle: 'Pendidikan',
      breadcrumb: [
        {
          text: 'Pendidikan',
          active: true,
        },
      ],
      middleware: 'Auth',
    },
  },

]
