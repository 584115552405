export default [
  {
    path: '/member',
    name: 'member',
    component: () => import('@/views/member/Member.vue'),
    meta: {
      pageTitle: 'Member',
      breadcrumb: [
        {
          text: 'Member',
          active: true,
        },
      ],
      middleware: 'Auth',
    },
  },

]
