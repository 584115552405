import resource from '../resource'

const endpoints = {
  list: '/user/web/member',
  listByFaculty: 'user/web/member/byFaculty',
  export: '/user/web/member/export',
  status: '/user/web/member/status',
  delete: '/user/web/member/delete',
  delete_role: '/user/web/role/delete',
  member_role: '/user/web/role',
  isPriority: '/user/web/member/isPriority',
  isNotPriority: '/user/web/member/isNotPriority',

}

export default {

  list: (data) => {
    const request = resource.get(`${endpoints.list}`, { params: data })
    return request
  },
  listByFaculty: (data) => {
    const request = resource.get(`${endpoints.listByFaculty}`, { params: data })
    return request
  },
  export: (data) => {
    const request = resource.get(`${endpoints.export}`, { params: data })
    return request
  },
  status: (data) => {
    const request = resource.post(`${endpoints.status}`, data)
    return request
  },
  delete: (data) => {
    const request = resource.post(`${endpoints.delete}`, data)
    return request
  },
  member_role: (data) => {
    const request = resource.get(`${endpoints.member_role}`, { params: data })
    return request
  },
  add_role: (data) => {
    const request = resource.post(`${endpoints.member_role}`, data)
    return request
  },
  delete_role: (data) => {
    const request = resource.post(`${endpoints.delete_role}`, data)
    return request
  },

  isPriority: (data) => {
    const request = resource.post(`${endpoints.isPriority}`, data)
    return request
  },
  isNotPriority: (data) => {
    const request = resource.post(`${endpoints.isNotPriority}`, data)
    return request
  },
}
