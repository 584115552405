import auth from './modules/auth'
import pendidikan from './modules/pendidikan'
import master from './modules/master'
import member from './modules/member'
import mentorship from './modules/mentorship'
export default {
  auth,
  pendidikan,
  master,
  member,
  mentorship

}
